$swiper-margin: 40px;

.swiper-slide__container{
    margin-top: $swiper-margin;

    @media screen and (min-width: 768px) {
        width: 90% !important;
    }
}

.swiper-slide__dec{
    display: flex;
    align-items: center;
}

.swiper-slide__dec-line{
    padding-top: $swiper-margin;
    width: 100%;
    border-top: 1px var(--color-primary) solid;
}

.swiper-slider__title{
    word-break: break-word;
    position: relative;
    z-index: 800;
    margin-left: -11.5rem;

    @media(min-width: 768px){
        word-break: unset;
        margin-left: 0;
    }
}

.swiper-slide__img{
    filter: brightness(60%);
    @media(min-width: 768px) {
        filter: brightness(100%);
    }
}

.swiper-slide__year{
    text-align: center;
    @media(min-width: 768px){
        text-align: unset;
    }
}