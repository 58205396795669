.partner__img{
    background-color: white;
}

.partner__img-link{
    transition: transform 120ms ease-out;
    will-change: transform;

    .partner__img:hover &, &:active, &:focus {
        transform: scale(.95);
    }
}