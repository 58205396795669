html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}

body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(var(--font-size-default) * 0.875) ;

    letter-spacing: 1px;
    line-height: calc(30 / 16);

    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
    }
}

.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    font-size: calc(36rem / 16);
}

h2, .h2 {
    font-size: calc(30rem / 16);
}

h3, .h3 {
    font-size: calc(24rem / 16);
}

h4, .h4 {
    font-size: calc(20rem / 16);
}

h5, .h5 {
    font-size: calc(18rem / 16);
}

h6, .h6 {
    font-size: 1em;
}

table {
    word-break: initial;
}

.emphasized {
    font-family: var(--font-termina-bold);
    font-size: calc(18rem / 16);
    letter-spacing: .8px;

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        letter-spacing: 1px;
    }
}


.lh-2 {
    line-height: 2;
}

.white-emphasized {
    font-family: var(--font-default-bold);
    font-size: clamp(1.625rem, calc(0.2083rem + 2.9514vw), 3.75rem);
    color: white;
    letter-spacing: 5px;
    line-height: clamp(2.25rem, calc(0.4167rem + 3.8194vw), 5rem);
}

.text-big {
    font-family: var(--font-termina);
    font-size: clamp(1.125rem, calc(-1.973rem + 9.8723vw), 9.375rem);
    letter-spacing: calc(5rem / 16);
    line-height: clamp(2.375rem, calc(0.0745rem + 12.4255vw), 12.5rem);
    text-align: center;
}

.font-family-avenir-black {
    font-family: var(--font-default-bold)
}
