$height: 13.375rem;

.cta-area__img-container{
    max-width: 1825px;
}

.cta-area__link-container{
    z-index: 3;
    position: relative;
    background-color: var(--color-background-dark);
    color: var(--color-primary);
    margin-top: -$height/4;
    transition: all 120ms ease-out;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
        padding: 2rem;
        margin-top: -$height/2;
        min-height: $height;
    }

    &:hover,
    &:active,
    &:focus, {
        color: var(--color-background-dark);
        background-color: var(--color-primary);
    }
}
.cta-area__link-container-inner {
    max-width: 23rem;
}

.cta-area__link-text{
    font-size: 1rem;
    letter-spacing: 2px;
    line-height: calc(26rem/16);
}

.cta-area__ratio {
    --bs-aspect-ratio: 50%;

    @media screen and (min-width: 768px) {
        --bs-aspect-ratio: 37.5%;
    }
}
