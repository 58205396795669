/* Add your font-face rules here e.g:
@font-face {
    font-family: 'font-name';
    src: url('fonts/font-name.woff2') format('woff2'),
    url('/static/fonts/font-name.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'font-name';
    src: url('fonts/font-name-bold.woff2') format('woff2'),
    url('fonts/font-name-bold.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}
*/

//TODO woff hinzufügen?

@font-face {
    font-family: 'AvenirLTProBlack';
    src: url('fonts/AvenirLTProBlack.woff2') format('woff2');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirLTProLight';
    src: url('fonts/AvenirLTProLight.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Termina-Regular';
    src: url('fonts/Termina-Regular.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Termina-Bold';
    src: url('fonts/Termina-Bold.woff2') format('woff2');
    font-weight: bold;
    font-display: swap;
}

/* Important:
*  Font urls have to be relative to the current file or start with the "fonts" alias e.g. "fonts/font-name.woff"
*  The "fonts" alias resolves to /web/static/fonts/
*/