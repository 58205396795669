.usp-area__benefits-list{
    list-style-type: none;
}

.usp-area{
    position: relative;
    overflow: hidden;
}

.usp-area__icons{
    display: block;
    bottom: 0;
    right: 10%;
    flex-direction: column;
    align-items: center;

    @media(min-width: 768px) {
        width: 100px;
        position: absolute;
        display: flex;
    }
}

.usp-area__content{
    margin-right: 20px;

    @media(min-width: 576px) {
        padding-left: 16.6%;
    }

    @media(min-width: 768px) {
        border-left: solid var(--color-primary) 1px;
        border-bottom: solid var(--color-primary) 1px;
        padding-right: 10%;
    }
}

.usp-area__icons-decoration{
    height: 0;
    @media(min-width: 768px) {
        height: 500px;
        border-right: solid 1px var(--color-primary);
        width: 1px;
    }
}

.usp-area__benefits-list{
    padding-right: 16.6%;
    padding-left: 0;

    @media(min-width: 576px) {
        padding-right: 0;
    }
}
