.career-area{
    margin-bottom: 10rem;
}

.career-area__headline{
    border-bottom: solid var(--color-primary) 1px;
    padding-top: 4vw;
    padding-bottom: 4vw;
}

.career-area__text{
    padding-top: 4vw;
}

.career-area__link-container{
    margin-top: 4vw;

    &:hover,
    &:active,
    &:focus, {
        color: var(--color-primary);
        background-color: var(--color-background-dark);
    }
}

.career-area__link{
    padding: 1rem;
}

.career-area__bg-container{
    position: absolute;
    width: 100vw;
    max-width: 1200px;
    right: 0;
    bottom: 17vw;
    z-index: -200;

    @media screen and (min-width: 768px) {
        width: 65vw;
        bottom: -4%;
    }
}

.career-area__bg-img{
    filter: brightness(20%);
}
