html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}

@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1730rem/16);
    width: 100%;
}

.main-content {
    padding-top: 6rem;
    padding-bottom: 3rem;

    @media screen and (min-width: 768px) {
        padding-top: clamp(5rem, 20vh , 12rem);
        padding-bottom: clamp(4rem, 15vh , 10rem);
    }
}