.circle-arrow__circle{
    font-size: calc(40rem/16);
}

.circle-arrow__arrow{
    font-size: calc(25rem/16);
    margin-left: -16px;
}

.circle-arrow__circle-mirrored{
    transform: rotate(180deg);
    margin: 0;
    margin-right: -36px;

    @media(min-width: 992px){
        margin-right: -43px;
    }
}

.circle-arrow__arrow-mirrored{
    transform: rotate(180deg);
    margin: 0;
}

.swiper-button-following, .swiper-button-previous{
    color: var(--color-primary);
}