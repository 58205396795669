@media screen and (min-width: 768px) {
    .content-connector {
        height: 10rem;
        width: 1px;
        background-color: var(--color-primary);
        margin: auto;
        margin-top: 6.25rem;
        margin-bottom: 6.25rem;
        transform: scaleY(1);
        transform-origin: top center;
        opacity: 1;
        will-change: transform;
    }
}