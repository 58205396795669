.scroll-animator {
    position: fixed;
    left: 1rem;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    transition: opacity 0.5s;

    @media screen and (min-width: 768px) {
        left: 3rem;
    }

    @media screen and (min-width: 1500px) {
        left: 5rem;
    }

    &.is-hidden {
        opacity: 0;
    }
}

.scroll-animator__decoration {
    //margin-top: 1rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
}

.scroll-animator__decoration-element {
    height: 100%;
    width: 1px;
    border-right: solid 1px var(--color-primary);
}

.scroll-animator__text {
    transform: rotate(-90deg) translateX(50%);
    transform-origin: center center;
    width: 1.875em;
}