.media-area__decoration{
    z-index: -300;
    bottom: -14%;
    right: -16.5%;
    position: absolute;
    width: 38vw;
    height: 16vw;
    background-color: var(--color-primary);
    
    @media (min-width: 1200px) {
        width: 28vw;
        height: 11vw;
    }
}

.media-area__wrapper{
    z-index: 1;
    position: relative;
}

.title-block{
    font-family: var(--font-termina);
}