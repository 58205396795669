.info-slide__text{
    z-index: 500;
    position: relative;
}

.info-slide__img{
    position: relative;
}

.info-slide__img-desc{
    margin-top: -50%;
    z-index: 200;

    @media(min-width: 576px){
        margin-top: -20%;
    }


    @media(min-width: 768px){
        margin-top: 0;
    }
}
.info-slide__img-content {
    @media screen and (max-width: 767px) {
        padding-left: 1.5rem;
        padding-top: 1.5rem;
        background-color: var(--color-light-grey);
    }
}

.info-slide__text-decoration{
    opacity: 0.5;
    position: absolute;
    bottom: calc(90rem / 16);
    left: -50%;
    width: 45vw;
    z-index: -200;

    @media screen and (min-width: 768px) {
        left: 70%;
        bottom: auto;
        top: 6.75rem;
        width: 20vw;
    }
}


@media screen and (min-width: 768px) {
    .info-slide__text-decoration--is-right{
        left: -80%;
    }
}

