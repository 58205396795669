.product-area__bg{
    position: absolute;
    top: calc(450rem / 16);
    left: 0;
    right: 28%;
    height: 55%;
    background-image: url('static/img/hiltl-texture-concrete.jpg');
    background-size: cover;
    z-index: -300;

    @media screen and (max-width: 767px) {
        display: none;
    }


    @media screen and (min-width: 1100px) {
        top: 25%;
    }
}



.product-area__product-detail--interactive {
    padding: 1rem;
    margin: -1rem;
    transition: all 120ms ease-out;
}

.product-area__product:hover{
    .product-area__product-detail--interactive {
        background: #1b1b1b;
        color:  var(--color-primary);
    }
}

.product-area__product-title {
    line-height: 1.5;
    font-family: var(--font-termina-bold);

    @media screen and (min-width: 768px) {
        font-size: 1.25rem;
        letter-spacing: 1px;
    }
}

.product-area__product--product-0{
    margin-top: 0;

    @media(min-width: 768px) {
        margin-top: -15vh;
    }

    @media(min-width: 1400px){
        margin-top: -35vh;
    }
}

.product-area__product--product-1{
    margin-top: 0;

    @media(min-width: 768px) {
        margin-top: -19vh;
    }

    @media(min-width: 1400px){
        margin-top: -39vh;
    }
}

.product-area__product--product-2{
    margin-top: 0;

    @media(min-width: 768px) {
        margin-top: -15vh;
    }

    @media(min-width: 1400px){
        margin-top: -35vh;
    }
}

.product-area__headline-container{
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;

    @media(min-width: 768px) {
        margin-top: 15vh;
        margin-bottom: 0;
    }

    @media(min-width: 1400px){
        margin-top: 15vh;
        margin-bottom: 20vh;
    }
}

.product-area__headline{
    max-width: 230px;
    padding: 0;
    margin: 0;
    line-height: 34px;
}

.product-area__decoration{
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
}

.decoration-element{
    width: 100%;
    border-top: solid var(--color-primary) 1px;
    height: 10px;
}

.product-area__product--product-0-deko{
    position: absolute;
    top: 15%;
    bottom: 15%;
    left: calc(-60rem / 16);
    width: 100%;
    background-color: var(--color-primary);


    @media screen and (min-width: 768px) {
        width: 23vw;
        height: 27vw;
        top: 25%;
        right: 0;
        bottom: auto;
    }
}

.product-area__product--product-1-deko{
    position: absolute;
    width: 110%;
    right: calc(-60rem / 16);
    top: calc(-20rem / 16);
    height: calc(100rem / 16);
    background-color: var(--color-primary);


    @media screen and (min-width: 768px) {
        right: -40%;
        top: -5%;
        width: 25vw;
        height: 9vw;
    }
}

.product-area__product--product-2-deko{
    position: absolute;
    background-color: var(--color-primary);
    z-index: -200;
    right: calc(-60rem / 16);
    bottom: calc(-140rem / 16);
    height: 65%;
    width: 80%;

    @media screen and (min-width: 768px) {
        width: 16vw;
        height: 27vw;
        bottom: -13%;
        right: -40%;
    }
}

