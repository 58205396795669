$navbar-height: 80px;
$navbar-height-small: 50px;

.navbar {
    position: fixed;
    background-color: var(--color-light-grey);
    z-index: 800;
    width: 100%;
    font-size: .75rem;

    @media screen and (min-width: 768px) {
        font-size: .875rem;
    }
}

.navbar__top {
    border-bottom: solid var(--color-border-light) 1px;
}

.navbar__logo {
    object-fit: cover;
    padding-left: 10px;
    height: $navbar-height-small;

    @media(min-width: 992px) {
        height: $navbar-height;
    }
}

.navbar__middle {
    margin-bottom: -2px;
}

.navbar__anchor-links-link {
    --padding-x: clamp(1rem, 2vw, 3rem);

    display: flex;
    align-items: center;
    height: $navbar-height-small;
    position: relative;

    @media(min-width: 992px) {
        height: $navbar-height;
        padding: 0 var(--padding-x);
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: var(--padding-x);
        right: var(--padding-x);
        bottom: -1px;
        height: 3px;
        background-color: var(--color-background-dark);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 120ms ease-out;
    }

    &.active, &:hover {
        &:after {
            transform: scaleX(1);
            transform-origin: left;
        }
    }
}

.navbar__right {
    display: flex;
}

.navbar__right-shop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $navbar-height-small;
    background-color: var(--color-primary);
    border-left: solid var(--color-border-light) 1px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    transition: all 120ms ease-out;

    @media(min-width: 992px) {
        height: $navbar-height;
        width: calc(200rem / 16);
    }

    &:hover,
    &:active,
    &:focus {
        background: #1b1b1b;
        color: var(--color-primary);
    }
}

.navbar__right-burger {
    border: none;
    border-left: 1px solid var(--color-border-light);
    height: $navbar-height-small;
    width: $navbar-height-small;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media(min-width: 992px) {
        height: $navbar-height;
        width: $navbar-height;
    }
}

.navbar__right-burger-line {
    background-color: var(--color-border-light);
    width: 1.25rem;
    height: .125rem;
    margin-bottom: .1875rem;
}

.is-collapsed .navbar__right-burger-line--top {
    margin-left: 5px;
    transform: rotate(45deg) translateX(-1px) translateY(-1px);
    transform-origin: 0;
}

.is-collapsed .navbar__right-burger-line--center {
    visibility: hidden;
}

.is-collapsed .navbar__right-burger-line--bottom {
    margin-left: 5px;
    transform: rotate(-45deg) translateX(-2px) translateY(+1px);
    transform-origin: 0;
}

.navbar__overlay {
    position: absolute;
    top: $navbar-height-small+1;
    background-color: var(--color-light-grey);
    height: calc(100vh - #{$navbar-height-small});
    width: 100vw;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 120ms ease-out, visibility 0.1ms 120ms;

    @media screen and (min-width: 992px) {
        display: none;
    }
}

.navbar__overlay.is-collapsed {
    transform: translateX(0);
    visibility: visible;
    transition: transform 120ms ease-out;
}

.navbar__overlay-list {
    margin: 0;
    padding: 0;
    flex: auto;
    margin-top: 25px;
}

.navbar__footer {
    flex: none;
    margin-bottom: .5rem;
}

.navbar__overlay-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid var(--color-primary) 1px;
}

.navbar__overlay-list-link {
    margin-bottom: -2px;
    display: flex;
    align-items: center;
    height: $navbar-height;
}

.navbar__overlay-sharing-wrapper {
    position: absolute;
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: end;
    bottom: 25vh;
}

.navbar__deco {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.navbar__deco-line {
    width: 100%;
    height: 1px;
    border-bottom: solid black 1px;
    border-color: var(--color-primary);
}


