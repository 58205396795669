.footer__deco{
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.footer__deco-line{
    width: 100%;
    height: 1px;
    border-bottom: solid black 1px;
    border-color: var(--color-primary);
}

.footer__links-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: center;

    @media(min-width: 768px){
        flex-direction: row;
    }
}

.footer__arrow-right{
    margin-left: 15px;
    font-size: 1.5rem;
    font-weight: bold;
}

.footer__links-list-item{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.footer__copyright {
    color: #5E5C66;
    @media screen and (max-width: 767px) {
        text-align: center;
    }
}