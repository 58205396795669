.intro-slide{
    position: relative;
}

.intro-slide__title{
    font-family: var(--font-termina);
    position: relative;
    //the calc is there, so that it isn't misinterpreted by scss
    font-size: calc(clamp(1.725rem, -2.5633rem + 11.2361vw, 9.375rem));
    color: var(--color-white);
    text-transform: uppercase;
    text-align: center;
    z-index: 200;
}

.intro-slide__content{

    margin-top: -26vw;

    @media(min-width: 576px) {
        margin-top: -7vw;
    }

    @media(min-width: 768px) {
        margin-top: -5vw;
    }

    @media(min-width: 1400px) {
        margin-top: -14vw;
    }
}

.intro-slide__img-wrapper {
    @media screen and (max-width: 767px) {
        position: relative;
    }
}

.intro-slide__bg-img-container{
    width: 85vw;
    max-width: 1200px;
    z-index: -200;
    position: absolute;
    right: 0;
    bottom: -2rem;

    @media screen and (min-width: 768px) {
        bottom: 0;
        width: 35vw;
    }


    @media(min-width: 1200px) {
        display: block;
        width: 65vw;
    }
}

.intro-slide__bg-img{
    filter: brightness(20%);
}

.intro-slide__text{
    margin-top: 5rem;

    @media screen and (min-width: 768px) {
        margin-top: 2rem;
    }

    @media(min-width: 1400px) {
        margin-bottom: 5rem;
    }
}
