.sharing-list__item{
    margin-top: 0;
    margin-right: 20px;

    @media(min-width: 768px){
        margin-top: 20px;
        margin-right: 0;
    }
}
.sharing-list__link {
    display: inline-block;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    font-size: 1.5rem;
    transition: all 120ms ease-out;

    &:hover, &:active, &:focus {
        color: var(--color-primary);
        background-color: #1b1b1b;
    }
}

.--is-horizontal .sharing-list__item{
    margin-top: 0;
    margin-right: 10px;
    margin-left: 10px;
}

.sharing-list{
    list-style-type: none;
    padding: 0;
    display: flex;

    @media(min-width: 768px) {
        flex-direction: column;
    }
}

.--is-horizontal{
    @media(min-width: 768px) {
        flex-direction: row;
    }
}

