.cookie-bar.cookie-bar{
    display: block;
    align-items: left;
    left:50%;
    right:auto;
    top:50%;
    bottom:auto;
    transform: translateX(-50%) translateY(-50%);
    padding: 1.5rem 2rem;
    width: 90vw;
    height:auto;
    z-index: 800;

    @media(min-width: 768px){
        width: 35rem;
        padding: 2.5rem 3.125rem;
    }
}
.cookie-bar__text {
    margin: 0;
}
.cookie-bar__buttons.cookie-bar__buttons {
    margin: 1rem 0 0 0;
    text-align:left;
}
.cookie-bar:before {
    content: "";
    position: fixed;
    top: -50vh;
    bottom: -50vh;
    left: -55vw;
    right: -55vw;
    background: rgba(0,0,0,.65);
    z-index: -1;
}
.cookie-bar a:not(.btn) {
    text-decoration:underline;
}
.cookie-bar__btn.cookie-bar__btn {
    margin: 0 0 .5rem 0;
}

@media(max-width: 767px){
    .cookie-bar__btn.cookie-bar__btn {
        width: 100%;
        margin: 0 0 1rem 0;
    }
}

@media(min-width: 768px){
    .cookie-bar__btn + .cookie-bar__btn {
        margin-left: 1rem;
    }
}
