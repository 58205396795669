.cookie-modal__close {
    background: none;
    border: none;
}
.cookie-modal__close {
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
}
.cookie-modal__close:before {
    content: "\EA0E";
}