.history-slider {
    position: relative;
}
.history-slider .swiper-wrapper {
    align-items: stretch;
}
.swiper-slide{
    width: 100% !important;
    padding-bottom: calc(150rem / 16);
    background-color: var(--color-background-dark);
    height: auto;

    @media(min-width: 768px){
        height: calc(1000rem / 16);
        padding-bottom: calc(150rem / 16);
    }
}

.swiper-slide__bg{
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.07;
    filter: blur(5px);
}

.swiper-slide__bg-year{
    position: absolute;
    right: -8rem;
    bottom: -5rem;
    font-size: clamp(21rem, calc(8.8864rem + 18.6364vw), 31.25rem);;
    opacity: 0.05;
    color: white;
    line-height: 1;
    font-family: var(--font-termina-bold);
}

.swiper-pagination{
    position: relative;
    text-align: unset;
    display: flex;
    justify-content: space-between;
}
.swiper-pagination__wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}
.swiper-pagination__container{
    right: 0;
    height: 150px;
    display: flex;
    align-items: center;
    width: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    //margin-left: .5rem;

    @media screen and (min-width: 768px) {
        &.container {
            width: 95vw;
            margin-left: auto;
        }
    }
}

.swiper-pagination__container::-webkit-scrollbar{
    display: none;
}

.swiper-pagination-bullets{
    border-bottom: solid 1px var(--color-primary);
    margin-top: -5px;
    margin-right: .5rem;
}
.swiper-pagination-bullet.swiper-pagination-bullet.swiper-pagination-bullet {
    min-width: 3rem;
    height: auto;
    background: none;
    margin: 0;
    opacity: 1;
    position: relative;
    flex-grow: 1;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 11px;
        left: 22px;
        right: 0;
        height: 1px;
        background: var(--color-primary);
    }
    &:last-child {
        flex-grow: 0;
        flex-basis: 24px;
        min-width: 0;

        &:after {
            display: none;
        }
    }
}
.swiper-pagination-bullet-item{
    position: relative;
    margin: 0 !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 6px;
    border: solid var(--color-background-dark) 5px !important;
    margin-bottom: -12px !important;
    background-color: var(--color-background-dark);
    width: .5rem;
    height: .5rem;
}
.swiper-pagination-bullet-active .swiper-pagination-bullet-item{
    background-color: var(--color-primary);
}

.swiper-pagination-bullet-decoration{
    top: -6px;
    bottom: -6px;
    left: -6px;
    right: -6px;
    position: absolute;
    border: solid var(--color-primary) 1px;
    opacity: 0.5;
}

.swiper-pagination-bullet-active .swiper-pagination-bullet-decoration,
.swiper-pagination-bullet:hover .swiper-pagination-bullet-decoration,
.swiper-pagination-bullet:active .swiper-pagination-bullet-decoration,
.swiper-pagination-bullet:focus .swiper-pagination-bullet-decoration {
    opacity: 1;
}

.swiper-pagination-bullet__year{
    color: var(--color-primary);
    margin-top: 1rem;
    width: 3rem;
    text-align: center;
    opacity: 0.5;
}

.swiper-pagination-bullet-active .swiper-pagination-bullet__year,
.swiper-pagination-bullet:hover .swiper-pagination-bullet__year,
.swiper-pagination-bullet:active .swiper-pagination-bullet__year,
.swiper-pagination-bullet:focus .swiper-pagination-bullet__year {
    opacity: 1;
}

.swiper__bg{
    background-color: var(--color-background-dark);
}

.swiper-button-following, .swiper-button-previous{
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin-top: calc(-75rem / 16);
}

.swiper-button-following{
    right: 3px;
}




